::-moz-selection {
    background: #00bfbf;
    color: #fafafa;
    text-shadow: none;
}
::selection {
    background: #00bfbf;
    color: #fafafa;
    text-shadow: none;
}
::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
html {
    overflow: scroll;
    overflow-x: hidden;
    font-size: 16px;
}
body {
    transition: all 0.5s ease;
}
p {
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
}


/* h2{
    color: blue;
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: light;
    font-size: 100;
} */


h4{
    /* color: blue; */
    font-size: 100;
    font-weight: 100;
    /* letter-spacing: .5rem; */
    /* background: #000000; */
    background: linear-gradient(to right, #5e5e5e 5%, #FFFFFF 25%, teal 55%, green 70%);

    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;

}


